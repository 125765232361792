<template>
	<div style="overflow: auto;">
		<div class="row ask-title ">
			<div class="text-ellipsis">回答：{{ detail.title }}</div>
		</div>
		<div class="editor-box">
			<editor ref="editorOne" v-model="content" :placeholder="editorTips" @change="change" :height="height"></editor>
			<div class="row cc btn" @click="submit()">发布</div>
		</div>
	</div>
</template>

<script>
import Editor from '@/components/wangEditor/index.vue';
export default {
	data() {
		return {
			content: '',
			height: 500,
			detail: {},
			editorTips: ''
		};
	},
	components: { Editor },
	async created() {
		// 减去工具栏的高度
		// this.height = window.screen.availHeight - 50 - 40;
		this.height = window.innerHeight - 50 - 40;
		let detailRes = await this.$service.post('question/detail', { id: this.$route.query.id });
		this.detail = detailRes.data;
		// 输入框提示词
		let editorTips = await this.$service.post('auth/answerTip');
		this.editorTips = editorTips.data.introduce;
	},
	activated() {
		this.content = '';
	},
	methods: {
		change(e) {},
		async submit() {
			if (this.content == '') {
				this.$toast.fail('请填写回答正文内容');
			} else {
				try {
					await this.$service.post('reply/answer', { question_id: this.$route.query.id, content: this.content });
					this.$toast.success({
						duration: 800,
						message: '回答成功'
					});
					this.$app.$emit('answerAddSuccess');
					setTimeout(() => {
						this.$router.go(-1);
					}, 800);
				} catch {}
			}
		}
	}
};
</script>

<style scoped lang="less">
/deep/ .w-e-toolbar {
	border: none !important;
}
/deep/ .w-e-text-container {
	border: none !important;
	// border-top: 1px solid #c9d8db !important;
}
.editor-box {
	position: relative;
	.btn {
		position: absolute;

		z-index: 999999;
		width: 1.973333rem;
		height: 0.8rem;
		background: @green;
		border-radius: 0.4rem;
		color: #ffffff;
		font-size: 0.32rem;
		top: 6px;
		right: 12px;
	}
}

.ask-title {
	height: 50px;
	color: #999999;
	font-size: 14px;
	align-items: center;
	width: 100%;
	padding: 0 14px;
	box-sizing: border-box;
	border-bottom: 1px solid #ededf1;
}
// /deep/ .w-e-toolbar {
// 	position: fixed;
// 	bottom: 0;
// }
</style>
